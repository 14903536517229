body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Helvetica Neue', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html, body, div#root {
  width: 100%;
  min-height: 100vh;
}

body {
  overflow-y: scroll;
}

* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline: none;
}

::selection {
  background: transparent;
  color: inherit;
}

::-moz-selection {
  background: transparent;
  color: inherit;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

a {
  text-decoration: none;
}
